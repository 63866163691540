<template>
    <div
        class="more-options"
        :class="{ mb: isMobileDevice }"
    >
        <more-vertical-icon
            class="more"
            @click="show = !show"
        />
        <div
            v-if="show"
            class="box-options"
        >
            <ul>
                <li @click="openModal()">
                    <p class="sm-sbold">
                        {{ $t('modal.confirm.editButton') }}
                    </p>
                </li>
                <li @click="openModal(true)">
                    <p class="sm-sbold">
                        {{ $t('modal.confirm.removeButton') }}
                    </p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    import MoreVerticalIcon from 'vue-feather-icons/icons/MoreVerticalIcon';
    import { mapMutations } from 'vuex';

    import UserAgentMixin from '@/mixins/userAgentMixin';
    export default {
        mixins: [UserAgentMixin],
        components: {
            MoreVerticalIcon,
        },
        name: 'MoreOption',
        props: {
            inputData: {
                type: Object,
                required: true,
            },
            showBox: {
                type: Number,
                required: true,
            },
            type: {
                type: String,
                default: 'delivery',
            },
        },
        data() {
            return {
                show: false,
            };
        },
        methods: {
            ...mapMutations('user', ['setModal']),
            closeModal() {
                this.$emit('closeModal');
            },
            openModal(isDelete = false) {
                let type = isDelete ? 'delete' : this.type;
                let action = isDelete ? 'delete' : 'update';

                let data = {
                    inputData: this.inputData,
                    action: action,
                    toDeleteType: isDelete ? this.type : null,
                };

                this.setModal({
                    show: true,
                    type: type,
                    data: data,
                });
            },
        },
        watch: {
            showBox() {
                if (this.showBox == 0) {
                    this.show = false;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
.more-options {
  position: relative;

  .box-options {
    box-shadow: $shadow-menu;
    box-shadow: 0px 4px 16px $gray-light;
    border-radius: 4px;
    position: absolute;
    right: 5px;
    top: 25px;
    background-color: $white;

    ul {
      li {
        padding: 4px 12px;

        p {
          cursor: pointer;
          color: $text;
        }

        @include media-breakpoint-up(md) {
          &:hover {
            background-color: $gray-light;
          }
        }
      }
    }
  }
}
</style>
