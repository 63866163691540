<template>
    <b-row>
        <b-col
            cols="12"
            class="box-section"
        >
            <p class="heading sm-sbold">
                {{ $t('page.checkout.shippingAddressesHeading') }}
            </p>
            <div
                v-if="deliveryData.length > 0"
                class="boxes"
            >
                <div
                    v-for="data in deliveryData"
                    :key="`delivery-${deliveryData.indexOf(data)}`"
                    class="box"
                    :class="{
                        selected: selectedData == data.id || data.selected,
                        loading: loading && data.id == newSelected,
                    }"
                    @mouseover="activeBox = data.id"
                    @mouseleave="activeBox = 0"
                >
                    <div
                        class="box-container"
                        @click="select(data.id)"
                    >
                        <div class="box-header">
                            <label>
                                <div class="custom-checkbox">
                                    <input
                                        v-model="selectedData"
                                        type="radio"
                                        name="delivery"
                                        :checked="checkSelected(data)"
                                        :value="data.id"
                                    >
                                    <div class="checkmark">
                                        <check-icon />
                                    </div>
                                </div>
                                <div class="text">{{ data.contact_label }}</div>
                            </label>
                        </div>
                        <div class="box-content">
                            <caption class="name">
                                {{
                                    data.contact_name
                                }}
                            </caption>
                            <caption class="address">
                                <span>
                                    {{ data.contact_county }}, {{ data.contact_city }},
                                    {{ data.contact_address }}</span>
                            </caption>
                            <caption class="phone">
                                {{
                                    data.contact_phone
                                }}
                            </caption>
                        </div>
                    </div>
                    <more-options
                        :input-data="data"
                        :show-box="activeBox"
                        type="delivery"
                    />
                </div>
            </div>
            <div class="box-actions">
                <b-button
                    variant="text"
                    class="stroke sm"
                    @click="openModal()"
                >
                    <plus-icon /><span>{{ $t('page.checkout.addAddressButton') }}</span>
                </b-button>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import PlusIcon from 'vue-feather-icons/icons/PlusIcon';
    import { mapActions, mapMutations, mapState } from 'vuex';

    import MoreOptions from '@/components/user/MoreOptions';

    export default {
        name: 'UserDeliveryData',
        components: { CheckIcon, PlusIcon, MoreOptions },
        props: {
            showModal: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                selectedData: '',
                newSelected: '',
                activeBox: 0,
                loading: false,
            };
        },
        computed: {
            ...mapState('user', ['deliveryData']),
        },
        methods: {
            ...mapActions('user', ['selectAddress']),
            ...mapMutations('user', ['setModal']),
            ...mapActions('checkout', ['getCheckoutData']),
            async select(id) {
                if (id != this.selectedData && !this.loading) {
                    this.newSelected = id;
                    this.loading = true;
                    await this.selectAddress({ id: id, type: 'delivery' });
                    this.loading = false;
                }
            },
            openModal() {
                this.setModal({
                    show: true,
                    type: 'delivery',
                    data: {
                        inputData: {},
                        action: 'add',
                    },
                });
            },
            checkSelected(data) {
                if (data.selected) {
                    this.selectedData = data.id;
                }
                return data.selected;
            },
        },
    };
</script>
