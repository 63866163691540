<template>
    <b-row>
        <b-col
            cols="12"
            class="box-section"
        >
            <div
                v-if="billingData.length > 0"
                class="boxes"
            >
                <div
                    v-for="data in billingData"
                    :key="`billing-${billingData.indexOf(data)}`"
                    class="box"
                    :class="{
                        selected: data.selected,
                        loading: loading && data.id == newSelected,
                    }"
                    @mouseover="activeBox = data.id"
                    @mouseleave="activeBox = 0"
                >
                    <div
                        class="box-container"
                        @click="select(data.id)"
                    >
                        <div class="box-header">
                            <label>
                                <div class="custom-checkbox">
                                    <input
                                        v-model="selectedData"
                                        type="radio"
                                        name="billing"
                                        :checked="checkSelected(data)"
                                        :value="data.id"
                                    >
                                    <div class="checkmark">
                                        <check-icon />
                                    </div>
                                </div>
                                <div class="text">{{ data.billing_label }}</div>
                            </label>
                        </div>
                        <div class="box-content">
                            <caption class="name">
                                {{
                                    data.billing_name
                                }}
                            </caption>
                            <caption class="address">
                                <span>
                                    {{ data.billing_county }}, {{ data.billing_city }},
                                    {{ data.billing_address }}</span>
                            </caption>
                            <caption class="phone">
                                {{
                                    data.billing_phone
                                }}
                            </caption>
                        </div>
                    </div>
                    <more-options
                        :input-data="data"
                        :show-box="activeBox"
                        type="billing"
                    />
                </div>
            </div>
            <div class="box-actions">
                <b-button
                    variant="text"
                    class="stroke sm"
                    @click="openModal()"
                >
                    <plus-icon /><span>{{ $t('page.checkout.addAddressButton') }}</span>
                </b-button>
            </div>
        </b-col>
    </b-row>
</template>

<script>
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import PlusIcon from 'vue-feather-icons/icons/PlusIcon';
    import { mapActions, mapMutations } from 'vuex';

    import MoreOptions from '@/components/user/MoreOptions';


    export default {
        name: 'UserBillingIndividual',
        components: { CheckIcon, MoreOptions, PlusIcon },
        props: {
            billingData: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                sameData: false,
                selectedData: '',
                newSelected: '',
                personType: 'individual',
                activeBox: 0,
                inputData: {},
                action: 'add',
                loading: false,
            };
        },
        methods: {
            ...mapActions('user', ['selectAddress']),
            ...mapMutations('user', ['setModal']),
            changePersonType(type) {
                this.personType = type;
            },
            async select(id) {
                if (id != this.selectedData && !this.loading) {
                    this.newSelected = id;
                    this.loading = true;
                    await this.selectAddress({ id: id, type: 'billing' });
                    this.loading = false;
                }
            },
            openModal() {
                this.setModal({
                    show: true,
                    type: 'billing',
                    data: {
                        inputData: {},
                        action: 'add',
                    },
                });
            },
            checkSelected(data) {
                if (data.selected) {
                    this.selectedData = data.id;
                }
                return data.selected;
            },
        },
    };
</script>
