<template>
    <div>
        <b-row>
            <b-col
                cols="12"
                class="box-section billing-dates"
                :class="{ user: page == 'user' }"
            >
                <p class="heading sm-sbold">
                    {{ $t('page.checkout.billingAddressesHeading') }}
                </p>
                <label
                    v-if="!deliveredViaEmail && page != 'user'"
                    class="same-data mt-3"
                >
                    <div class="custom-checkbox">
                        <input
                            v-model="sameData"
                            type="checkbox"
                            name="compare"
                            @change="checkIfPersonTypeNeedsToBeChanged()"
                        >
                        <div class="checkmark">
                            <check-icon />
                        </div>
                    </div>
                    <div class="text">{{ $t('page.checkout.sameDateLabel') }}</div>
                </label>
                <div
                    v-if="!sameData && page != 'user'"
                    class="person-type mt-3"
                >
                    <div
                        class="individual"
                        :class="{ selected: personType == 'individual' }"
                        @click="changePersonType('individual')"
                    >
                        <p class="sm-sbold">
                            {{ $t('page.checkout.individualPersonText') }}
                        </p>
                    </div>
                    <div
                        class="legal"
                        :class="{ selected: personType == 'legal' }"
                        @click="changePersonType('legal')"
                    >
                        <p class="sm-sbold">
                            {{ $t('page.checkout.legalPersonText') }}
                        </p>
                    </div>
                </div>
            </b-col>
        </b-row>

        <user-billing-individual
            v-if="showBilling"
            :billing-data="billingData"
        />
        <b-row v-if="page == 'user'">
            <b-col
                cols="12"
                class="box-section billing-dates user"
            >
                <p class="heading sm-sbold">
                    {{ $t('page.checkout.legalPersonText') }}
                </p>
            </b-col>
        </b-row>
        <user-company-data
            v-if="showCompany"
            :company-data="companyData"
        />
    </div>
</template>

<script>
    import CheckIcon from 'vue-feather-icons/icons/CheckIcon';
    import { mapActions, mapState } from 'vuex';

    import UserBillingIndividual from './UserBillingIndividual';
    import UserCompanyData from './UserCompanyData';
    export default {
        name: 'UserBillingData',
        components: { CheckIcon, UserBillingIndividual, UserCompanyData },
        props: {
            deliveredViaEmail: {
                type: Boolean,
                default: true,
            },
            page: {
                type: String,
                default: 'user',
            },
        },
        data() {
            return {
                sameData: false,
                personType: 'individual',
            };
        },
        computed: {
            ...mapState('user', ['billingData', 'companyData']),
            ...mapState('checkout', ['selectedData']),
            showBilling() {
                return (
                    (!this.sameData && this.personType == 'individual') ||
                    this.page == 'user'
                );
            },
            showCompany() {
                return (
                    (!this.sameData && this.personType == 'legal') || this.page == 'user'
                );
            },
        },
        methods: {
            ...mapActions('user', ['selectAddress']),
            ...mapActions('checkout', ['getCheckoutData']),

            changePersonType(type) {
                this.personType = type;
                localStorage.setItem('personType', this.personType);
                this.getCheckoutData();
            },
            checkIfPersonTypeNeedsToBeChanged(){
                localStorage.setItem('sameData', this.sameData);
                if(this.sameData){
                    this.changePersonType('individual');
                }
            },
            initialize() {
                this.sameData = this.selectedData.same_data;
                this.personType =  this.selectedData.person_type;
            },
        },
        mounted() {
            if(this.page == 'checkout'){
                this.initialize();
            }
        },
        watch: {
            'selectedData.same_data': {
                handler() {
                    this.initialize();
                },
            },
            'selectedData.person_type': {
                handler() {
                    this.initialize();
                },
            },
        },
    };
</script>
